<template>
  <v-container class="fill-height" fluid>
      <v-row v-if="this.currentModel">
          <v-col cols="12" md="12">
            <v-card class="element" width="100%" style="padding: 10px" outlined>
                                <h2 v-if="this.piezo.altIdentifier == null || this.piezo.altIdentifier.length == 0 " class="text-center text-uppercase">{{currentModel.libelle}}
                    <span style="float: right; margin-right: 10px">
                        <v-tooltip left class="info">
                            <template v-slot:activator="{ on, attrs }">

                                <v-icon color="blue" @click="dialog = !dialog" v-bind="attrs" v-on="on">mdi-information</v-icon>
                            </template>
                            <span>{{ $t("infoModel")}}</span>
                        </v-tooltip>
                    </span>
                </h2>
                <h2 v-else class="text-center text-uppercase">{{currentModel.libelle}} ({{this.piezo.altIdentifier}})
                    <span style="float: right; margin-right: 10px">
                        <v-tooltip left class="info">
                            <template v-slot:activator="{ on, attrs }">

                                <v-icon color="blue" @click="dialog = !dialog" v-bind="attrs" v-on="on">mdi-information</v-icon>
                            </template>
                            <span>{{ $t("infoModel")}}</span>
                        </v-tooltip>
                    </span>
                </h2>

            </v-card>
          </v-col>
      </v-row>
      <v-row v-if="this.currentModel &&(this.currentModel.logoPartenaire || this.currentModel.descriptionPartenaire) ">
          <v-col cols="12" md="12">
            <v-card class="element" width="100%" style="padding: 3px" outlined>
                <div v-if="this.currentModel.logoPartenaire" style="position: absolute; height: 48px; display: inline-block">
                    <img :src="this.currentModel.logoPartenaire" style="height: 42px" />
                </div>
                <div v-if="this.currentModel.descriptionPartenaire"> 
                    <h3 v-if="this.currentModel.logoPartenaire"  class="text-center text-uppercase" style="line-height: 42px">{{currentModel.descriptionPartenaire}}</h3>
                    <h3 v-if="this.currentModel.descriptionPartenaire.toLowerCase().includes('avertissement')" class="text-center" style="line-height: 42px">{{ $t("warning")}}</h3>
                    <p v-if="this.currentModel.descriptionPartenaire.toLowerCase().includes('avertissement')" class="text-center" style="line-height: 32px">{{currentModel.descriptionPartenaire.replace('Avertissement :',"")}} </p> 
                </div>
                
            </v-card>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" md="6">
            <v-card width="100%" class="fill-height" outlined>
               <MiniMap element  :Height="444" v-if="this.currentModel" :key="this.mapKey" :zoom="10" :model="currentModel" />
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card width="100%" outlined>
                <v-container element v-if="pluvio && this.piezo.source.libelle.toLowerCase().replace(/\s/g,'') != 'producteurtiershorsfrance'">
                    <Chart :Dark="this.userPrefs.darkTheme" :Height="420" v-if="currentModel && !debit" :Title="this.$t('piezo')+' ' + this.piezo.identifier" :Piezo="this.piezo.identifier" :Pluvio="this.pluvio.identifier" :ZoomDate="zoomStart" :StartDate="dateStart" :LinkAddress="'https://ades.eaufrance.fr/Fiche/PtEau?Code=' + this.piezo.identifier" />
                    <Chart :Dark="this.userPrefs.darkTheme" :Height="420" v-else-if="currentModel && debit" :Title="this.$t('piezo')+' ' + this.piezo.identifier" :Piezo="this.piezo.identifier" :Pluvio="this.pluvio.identifier" :Debit="this.debit.identifier" :ZoomDate="zoomStart" :StartDate="dateStart" :LinkAddress="'https://ades.eaufrance.fr/Fiche/PtEau?Code=' + this.piezo.identifier" />
                </v-container>
                <v-container element v-if="pluvio && this.piezo.source.libelle.toLowerCase().replace(/\s/g,'') == 'producteurtiershorsfrance'">
                    <Chart :Dark="this.userPrefs.darkTheme" :Height="420" v-if="currentModel && !debit" :Title="this.$t('piezo')+' ' + this.piezo.identifier" :Piezo="this.piezo.identifier" :Pluvio="this.pluvio.identifier" :ZoomDate="zoomStart" :StartDate="dateStart"/>
                    <Chart :Dark="this.userPrefs.darkTheme" :Height="420" v-else-if="currentModel && debit" :Title="this.$t('piezo')+' ' + this.piezo.identifier" :Piezo="this.piezo.identifier" :Pluvio="this.pluvio.identifier" :Debit="this.debit.identifier" :ZoomDate="zoomStart" :StartDate="dateStart"/>
                </v-container>
            </v-card>
          </v-col>
      </v-row>
      <v-row>
          <v-col>
            <v-card width="100%" outlined>
                <v-container element>
                    <Chart :Dark="this.userPrefs.darkTheme" :Height="690" v-if="currentModel && this.piezo.source.libelle.toLowerCase().replace(/\s/g,'') != 'producteurtiershorsfrance'" :IncludeIPS="isPremium" :AllowDownload="isPremium" :ModelId="this.currentModel.id" :Title="this.$t('piezo')+' ' + this.piezo.identifier" :Piezo="this.piezo.identifier" :IncludeSeuils="true" :ZoomDate="zoomStart" :StartDate="dateStart" :LinkAddress="'https://ades.eaufrance.fr/Fiche/PtEau?Code=' + this.piezo.identifier" />
                    <Chart :Dark="this.userPrefs.darkTheme" :Height="690" v-else-if="currentModel && this.piezo.source.libelle.toLowerCase().replace(/\s/g,'') == 'producteurtiershorsfrance'" :IncludeIPS="isPremium" :AllowDownload="isPremium" :ModelId="this.currentModel.id" :Title="this.$t('piezo')+' ' + this.piezo.identifier" :Piezo="this.piezo.identifier" :IncludeSeuils="true" :ZoomDate="zoomStart" :StartDate="dateStart" />
                </v-container>
            </v-card>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" md="6"  v-if="currentModel && pluvio">
            <v-card width="100%" outlined>
                <v-container element>
                    <Chart :Dark="this.userPrefs.darkTheme" v-if="currentModel && pluvio && this.piezo.source.libelle.toLowerCase().replace(/\s/g,'') != 'producteurtiershorsfrance'" :Title=" this.$t('pluvio')+' '+ this.pluvio.identifier" :Pluvio="this.pluvio.identifier" :ZoomDate="zoomStart" :StartDate="dateStart" :LinkAddress="/*this.pluvio.source && this.pluvio.source.libelle === 'ERA5' ? 'https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-single-levels?tab=overview' : */'https://donneespubliques.meteofrance.fr/?fond=produit&id_produit=90&id_rubrique=32'" />
                    <Chart :Dark="this.userPrefs.darkTheme" v-else-if="currentModel && pluvio && this.piezo.source.libelle.toLowerCase().replace(/\s/g,'') == 'producteurtiershorsfrance'" :Title=" this.$t('pluvio')+' '+ this.pluvio.identifier" :Pluvio="this.pluvio.identifier" :ZoomDate="zoomStart" :StartDate="dateStart"/>

                </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" v-if="currentModel && debit">
            <v-card width="100%" outlined>
                <v-container element>
                    <Chart v-if="this.debit.source.libelle.toLowerCase().replace(/\s/g,'') != 'producteurtiershorsfrance' " :Dark="this.userPrefs.darkTheme" :Title="this.$t('flowStation')+' '+ this.debit.identifier" :Debit="this.debit.identifier" :ZoomDate="zoomStart" :StartDate="dateStart" :LinkAddress="'http://www.hydro.eaufrance.fr/sitehydro/' + this.debit.identifier.substring(0, 8) + '/fiche'" />
                    <Chart v-else :Dark="this.userPrefs.darkTheme" :Title="this.$t('flowStation')+' '+ this.debit.identifier" :Debit="this.debit.identifier" :ZoomDate="zoomStart" :StartDate="dateStart"/>

                </v-container>
            </v-card>
          </v-col>
      </v-row>
      
    <v-dialog v-model="dialog" width="900" v-if="this.currentModel">
        <v-card>
            <v-card-title class="headline">
            {{ $t("infoModel")}}
            </v-card-title>

            <v-card-text>
            <div class="body-1" v-html="this.currentModel.description.replace(/(?:\r\n|\r|\n)/g, '<br /><br />')"></div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="dialog = false"
            >
                Fermer
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container> 
</template>
<script>
import Chart from '@/components/Chart.vue'
import MiniMap from '@/components/MiniMap.vue'
import ModelService from '../services/model.service'
import SensorService from '../services/sensor.service'
import { mapGetters } from 'vuex'

export default {
    name: 'Model',
    components:{
        Chart,
        MiniMap
    },
    data() {
        return {
            currentModel: null,
            mapKey: 0,
            pluvio: null,
            dialog: false
        }
    },
    computed: {
        ...mapGetters({
            userPrefs: 'auth/userPrefs',
            isPremium: 'auth/isPremium'
        }),
        currentId() {
            return this.$route.params.id
        },
        currentBSS() {
            return this.libelle
        },
        piezo () {
            return this.getSensorByType('Piezo')
        },
        etp () {
            return this.getSensorByType('ETP')
        },
        debit () {
            return this.getSensorByType('Debit')
        },
        zoomStart () {
            let d = new Date()
            d.setDate(d.getDate() - 90)

            return d
        },
        dateStart () {
            let d = new Date()
            d.setDate(d.getDate() - 365.25 * 10)

            return d
        }
    },
    async mounted(){
        await this.getCurrentModel()
    },
    beforeDestroy() {
        this.currentModel = null
    },
    methods: {
        refreshMap() {
            this.mapKey++
        },
        async getCurrentModel() {
            this.currentModel = await ModelService.getModel(this.currentId)
            let piezo = this.piezo

            //TODO: Afficher poste ERA5 quand les données seront disponibles
           if(this.getSensorByType('Pluie').source.libelle.toLowerCase() != 'producteur tiers hors france')
                this.pluvio = await SensorService.getNearestCapteur('Pluie', 'SYNOP', piezo.coords.geometry.coordinates[0], piezo.coords.geometry.coordinates[1])
           else 
               this.pluvio = this.getSensorByType('Pluie')
        },
        getSensorByType(type) {
            if(this.currentModel)
                try {
                    return this.currentModel.donneeModele.map(m => m.capteur).filter(x => x.typeDonnee.type.toLowerCase() === type.toLowerCase()).reduce(() => {})
                } catch {
                    return null
                }
            else
                return null
        }
    }
}
</script>
<style scoped>
.wideChart {
    width: 100%;
    height: 500px;
}
.container.element {
    margin: 0;
    width: 100%;
    max-width: 100% !important;
}
</style>