<template>
    <div :style="this.Height ? 'height: ' + this.Height + 'px' : ''">
        <template v-if="loading">
            <v-container>
                <v-row>
                    <v-col md="4">
                        <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4" offset="4">
                        <v-skeleton-loader type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col md="4">
                        <v-skeleton-loader type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row  dense>
                    <v-col md="12">
                        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" :md="modelData && modelData.length > 0 ? 6 : 12">
                    <div>
                        <v-btn-toggle class="mr-1">
                            <v-btn x-small @click="zoomPlot(fifteenDays)">{{ $t('last15days') }}</v-btn>
                            <v-btn x-small @click="zoomPlot(oneMonth)">{{ $t('last30days') }}</v-btn>
                            <v-btn x-small @click="zoomPlot(startYear)">{{ $t('year') }} {{ startYear.getFullYear() }}</v-btn>
                            <v-btn x-small @click="zoomPlot(tenYear)">{{ $t('years10') }}</v-btn>
                        </v-btn-toggle>
                        <v-btn class="mr-1" outlined color="secondary" elevation="0" x-small v-if="IncludeIPS" @click="toggleIps()">
                            <template v-if="ipsEnabled">
                                {{$t('hideSPI')}}
                            </template>
                            <template v-else>
                                {{$t('showSPI')}}
                            </template>
                        </v-btn>
                        <v-btn elevation="0" outlined color="secondary" x-small @click="exportChart()">{{$t('downloadGraph')}}</v-btn>
                        <v-btn class="ml-1" outlined color="secondary" elevation="0" x-small v-if="AllowDownload" @click="downloadCurrentResult()">
                            {{$t('expostCSV')}}
                        </v-btn>
                    </div>
                    <v-container class="ips-legend" v-if="ipsEnabled && IncludeIPS">
                        <v-chip small label class="mr-1"><v-icon left :color="getIPSColor('dix_ans_sec')" small>mdi-square-rounded</v-icon> {{ $t('verylowlevel') }}</v-chip>
                        <v-chip small label class="mr-1"><v-icon left :color="getIPSColor('cinq_ans_sec')" small>mdi-square-rounded</v-icon> {{ $t('lowlevel') }}</v-chip>
                        <v-chip small label class="mr-1"><v-icon left :color="getIPSColor('deux_ans_sec')" small>mdi-square-rounded</v-icon> {{ $t('modlowlevel') }}</v-chip>
                        <v-chip small label class="mr-1"><v-icon left :color="getIPSColor('deux_ans_hum')" small>mdi-square-rounded</v-icon> {{ $t('meanlevel') }}</v-chip>
                        <v-chip small label class="mr-1"><v-icon left :color="getIPSColor('cinq_ans_hum')" small>mdi-square-rounded</v-icon> {{ $t('modhighlevel') }}</v-chip>
                        <v-chip small label class="mr-1"><v-icon left :color="getIPSColor('dix_ans_hum')" small>mdi-square-rounded</v-icon> {{ $t('highlevel') }} </v-chip>
                        <v-chip small label class="mr-1"><v-icon left :color="getIPSColor('sup_dix_ans_hum')" small>mdi-square-rounded</v-icon> {{ $t('veryhighlevel') }} </v-chip>
                    </v-container>
                </v-col>
                <v-col cols="12" md="3" v-if="modelData && modelData.length > 0">
                    <!-- :item-text="item => `Prévision du ${new Date(item.dateDebut).toLocaleDateString()} au ${new Date(item.dateFin).toLocaleDateString()} (Calculée le ${new Date(item.dateCalcul).toLocaleString()})`" -->
                    <v-select v-on:change="switchToModelResult" dense hide-details :no-data-text="$t('noResult')" :label="$t('forecast')" :value="selectedModelResult" :items="availableModelResults" :return-object="true">
                        <template v-slot:item="data">
                            <v-list-item dense v-on="data.on" v-bind="data.attrs" :inactive="true" :link="true" style="cursor: pointer">                      
                                <v-list-item-icon>
                                    <v-icon color="success" v-if="data.item.id === availableModelResults[0].id">mdi-new-box</v-icon>
                                    <v-icon v-else></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('forecast') }} {{new Date(data.item.dateDebut).toLocaleDateString()}} {{ $t('to') }} {{new Date(data.item.dateFin).toLocaleDateString()}}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t('calculDate') }} {{new Date(data.item.dateCalcul).toLocaleString()}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="data">      
                            <v-list-item dense v-on="data.on" v-bind="data.attrs" :inactive="true" :link="true" style="cursor: pointer">                    
                                <v-list-item-icon v-if="data.item.id === availableModelResults[0].id">
                                    <v-icon color="success">mdi-new-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('forecast') }} {{new Date(data.item.dateDebut).toLocaleDateString()}} {{ $t('to') }} {{new Date(data.item.dateFin).toLocaleDateString()}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="3" v-if="modelData && modelData.length > 0">
                    
                    <v-select v-on:change="switchPrevision" dense hide-details :no-data-text="$t('noScenario')" :label="$t('scenarioSampling')" :value="selectedScenario" :items="modelData" :return-object="true" >
                        <template v-slot:item="data">
                            <v-list-item dense v-on="data.on" v-bind="data.attrs" :inactive="true" :link="true" style="cursor: pointer">
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.scenarioTitle}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="data">
                            <v-list-item dense v-on="data.on" v-bind="data.attrs" :inactive="true" :link="true" style="cursor: pointer">
                                <v-list-item-content>
                                    <v-list-item-title>{{data.item.scenarioTitle}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <h3 class="chart-title">
                    <template v-if="LinkAddress">
                        {{Title}} <a :href="LinkAddress" target="_blank"><v-icon color="primary">mdi-link-box-outline</v-icon></a>
                    </template>
                    <template v-else>
                        {{Title}}
                    </template>
                </h3>
            </v-row>
            <v-row>
                <div id="chart" class="chart">
                </div>
            </v-row>
        </template>
    </div>
</template>
<script>
import SensorService from '../services/sensor.service'
import ModelService from '../services/model.service'
import * as CanvasJS from '../assets/js/canvasjs.min'
import modelService from '../services/model.service'


export default {
    name: 'Chart',
    components: {
    },
    data () {
        return {
            piezoData: null,
            pluvioData: null,
            etpData: null,
            prelevementData: null,
            debitData: null,
            debitDataRealTime:null,
            modelData: null,
            availableModelResults: null,
            selectedModelResult: null,
            selectedScenario: null,
            minDate: null,
            maxDate: null,
            maxDateDebit: null,
            ips: null,
            ipsArea: null,
            layout: {
                animationEnabled: true,
                animationDuration: 500,
                zoomEnabled: true,
                height: this.Height - 100 ?? null,
                theme: this.Dark ? 'dark2' : 'light1',
                backgroundColor: 'transparent',
            /*    title: {
                    text: this.Title,
                    fontFamily: 'Roboto',
                    fontSize: 20,
                    padding: 10
                }, */
                axisX: {
                    valueFormatString: "DD/MM/YYYY",
                    viewportMinimum: this.ZoomDate,
                    viewportMaximum: this.nextThirtyDays,
                    labelFontFamily: 'Roboto',
                    labelFontSize: 10
                },
                toolTip: {
                    shared: true,
                    reversed: true,
                    borderColor: 'accent',
                    contentFormatter: function(e) {
                        var date = new Date(e.entries[0].dataPoint.x)
                        var content = `<div style="text-align: center; font-weight: bold">${date.toLocaleDateString()} ${date.toLocaleTimeString()}</div>`

                        for(var i = 0; i < e.entries.length; i++) {
                            var entry = `<span style="font-weight: bold; color: ${e.entries[i].dataSeries.color}">${e.entries[i].dataSeries.name}</span>: <span>${e.entries[i].dataPoint.y.toFixed ? e.entries[i].dataPoint.y.toFixed(2) : e.entries[i].dataPoint.y}</span>`
                            content += entry + '<br />'
                        }
                        return content
                    }
                },
                axisY: [],
                data: [],
                legend: {
                    horizontalAlign: 'left',
                    verticalAlign: 'top',
                    cursor: 'pointer',
                    fontSize: 15,
                    fontFamily: 'Roboto',
                    dockInsidePlotArea: false,
                    itemclick: function(e) {
                        if(typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
                            e.dataSeries.visible = false
                        } else {
                            e.dataSeries.visible = true
                        }
                        e.chart.render()
                    }
                }
            },
            loading: true,
            destroyChart: false,
            refreshingChart: false,
            chart: null,
            ipsEnabled: false
        }
    },
    computed: {
        plotDiv() {
            return this.$el.querySelector('#chart')
        },
        startYear() {
            return new Date(new Date().getFullYear() + '-01-01')
        },
        tenYear() {
            let d = new Date()
            d.setDate(d.getDate() - 365.25 * 10)
            return d
        },
        oneMonth() {
            let d = new Date()
            d.setDate(d.getDate() - 30)
            return d
        },
        fifteenDays() {
            let d = new Date()
            d.setDate(d.getDate() - 15)
            return d
        },
        nextThirtyDays() {
            let d = new Date()
            d.setDate(d.getDate() + 30)
            return d
        }
    },
    watch: {
        Dark: function() {
            this.drawChart()
        }
    },
    props: {
        Piezo: String,
        Pluvio: String,
        ETP: String,
        Prelevement: String,
        Debit: String,
        ZoomDate: Date,
        StartDate: Date,
        realTime: Boolean,
        Title: String,
        ModelId: Number,
        Height: Number,
        Dark: Boolean,
        IncludeSeuils: Boolean,
        IncludeIPS: Boolean,
        AllowDownload: Boolean,
        LinkAddress: String
    },
    mounted() {
        CanvasJS.addCultureInfo("fr",
                {
                    decimalSeparator: ",",
                    digitGroupSeparator: " ",
                    days: [this.$t('sunday'), this.$t('monday'), this.$t('tuesday'), this.$t('wednesday'), this.$t('thursday'), this.$t('friday'), this.$t('saturday')],
               });

        this.getData().then(() => {
            this.loading = false
        })
    },
    updated() {
        if(!this.refreshingChart)
            this.drawChart()
    },
    methods: {
        drawChart() {    
            this.refreshingChart = true
            if(this.layout && this.layout.data.length > 0) {
                this.layout.theme = this.Dark ? 'dark2' : 'light1'
                if(!this.chart)
                    this.chart = new CanvasJS.Chart(this.plotDiv, this.layout)

                this.chart.render()
            }
            this.refreshingChart = false
        },
        async loadIps() {
          try {
            this.ips = await ModelService.getIPS(this.Piezo)
            this.ipsArea = this.getIpsArea()           
          }
          catch(e) {
            this.ips = null
            this.ipsArea = null
          }
        },
        async getData() {
            if(this.Piezo){
                await SensorService.getOnlySensorData('piezo', this.Piezo, this.StartDate).then(async (data) => {
                    this.minDate = data[0].x
                    this.maxDate = data[data.length - 1].x

                    this.piezoData = {
                      dataPoints: data,
                      type: 'spline',
                      lineThickness: 3,
                      name: `${this.$t('piezo')} ${this.Piezo}`,
                      axisYIndex: this.addYAxis(this.$t('niveaumngf'), '#4791ff'),
                      showInLegend: true,
                      color: '#4791ff'
                    }

                    if(this.IncludeIPS) {    
                        await this.loadIps()
                        if(this.ipsEnabled)
                            this.showIps()
                    }

                    this.layout.data.push(this.piezoData)

                    let realTimeStartDate = new Date(this.piezoData.dataPoints[this.piezoData.dataPoints.length - 1].x)
                    realTimeStartDate.setDate(realTimeStartDate.getDate() + 1)

                    await SensorService.getOnlyRealTimePiezoData(this.Piezo, realTimeStartDate).then(rt => {
                        let realTimeData = {
                            dataPoints: rt,
                            type: 'line',
                            lineThickness: 3,
                            markerType: 'triangle',
                            name: `${this.$t('realtimelevel')}`,
                            axisYIndex: this.getYAxis(this.$t('niveaumngf')),
                            showInLegend: true,
                            color: 'limegreen'
                        }
                        this.layout.data.push(realTimeData)
                    }).catch(() => {})
                })
            }
            if(this.Pluvio){
                await SensorService.getNearestCapteur('pluie', 'synop', )
                await SensorService.getOnlySensorData('pluie', this.Pluvio, this.StartDate).then(data => {    
                    this.pluvioData = {
                      dataPoints: data,
                      type: 'column',
                      name: `${this.$t('rainPost')} ${this.Pluvio}`,
                      axisYIndex: this.addYAxis(this.$t('rain'), '#78dfe3'),
                      showInLegend: true,
                      color: '#78dfe3'
                    }
                    this.layout.data.push(this.pluvioData)
                })
            }
            if(this.ETP){
                await SensorService.getOnlySensorData('etp', this.ETP, this.StartDate).then(data => {
                    this.etpData = {
                      dataPoints: data,
                      type: 'column',
                      name: `${this.$t("postETP")} ${this.ETP}`,
                      axisYIndex: this.addYAxis(this.$t('etpmm'), '#db9c1f'),
                      showInLegend: true,
                      color: '#db9c1f'
                    }
                    this.layout.data.push(this.etpData)
                })
            }
            if(this.Prelevement){
                await SensorService.getOnlySensorData('prelevement', this.Prelevement, this.StartDate).then(data => {
                    this.prelevementData = {
                      dataPoints: data,
                      type: 'spline',
                      name: `${this.$t('observation')} ${this.Prelevement}`,
                      axisYIndex: this.addYAxis(this.$t('sampling'), '#db9c1f'),
                      showInLegend: true,
                    }
                    this.layout.data.push(this.prelevementData)  
                })
            }
            if(this.Debit){
                await SensorService.getOnlySensorData('debit', this.Debit, this.StartDate, false).then(data => {

                    this.debitData = {
                      dataPoints: data,
                      type: 'spline',
                      name: `${this.$t('flowStation')} ${this.Debit}`,
                      axisYIndex: this.addYAxis(this.$t('flowUnit'), '#db9c1f'),
                      showInLegend: true,
                      color: '#db9c1f'
                    }
                    this.layout.data.push(this.debitData)
                })
                let realTimeStartDate = new Date(this.debitData.dataPoints[this.debitData.dataPoints.length - 1].x)
                realTimeStartDate.setDate(realTimeStartDate.getDate() + 1)

                await SensorService.getOnlySensorData('debit', this.Debit, realTimeStartDate, true).then(data => {
                    this.debitDataRealTime = {
                      dataPoints: data,
                      markerSize: 0,
                      type: 'line',
                      name: `${this.$t('realtimeflow')}`,
                      axisYIndex: this.getYAxis(this.$t('flowUnit')),
                      showInLegend: true,
                      color: '#ffb700'
                    }
                    this.layout.data.push(this.debitDataRealTime)
                })
            }
            if(this.ModelId) {
                ModelService.getAvailableModelResult(this.ModelId).then(async data => {
                    this.availableModelResults = data

                    await ModelService.getLatestModelResult(this.ModelId).then(data => {
                        this.modelData = data
                        this.selectedModelResult = this.availableModelResults[0]
                        this.modelData.forEach(d => {
                            if(d.anneePrelevements === 0) {
                                d.scenarioTitle = `${this.$t('samplingDontUse')}`
                            } else if (d.scenarioPrelevements === 'AnneeSpecifique') {
                                d.scenarioTitle = `${this.$t('speYear')} ( ${this.$t('basedOn')} ${d.anneePrelevements})`
                            } else {
                                d.scenarioTitle = `${this.$t(d.scenarioPrelevements)} ( ${this.$t('basedOn')} ${d.anneePrelevements})`
                            }
                        })

                        if(this.modelData.length === 3) {
                            this.selectedScenario = this.modelData[1]
                        } else {
                            this.selectedScenario = this.modelData[0]
                        }

                        this.addScenario(this.$t('samplingnorain'))
                        this.addScenario(this.$t('sampling10dry'))
                        this.addScenario(this.$t('sampling5dry'))
                        this.addScenario(this.$t('samplingMean'))
                        this.addScenario(this.$t('sampling5wet'))
                        this.addScenario(this.$t('sampling10wet'))
                    })
                }).catch(() => {
                    this.availableModelResults = null
                })

                if(this.IncludeSeuils) {
                    var sixMonth = new Date();
                    var numberOfDaysToAdd = 180;
                    sixMonth.setDate(sixMonth.getDate() + numberOfDaysToAdd); 

                    this.addSeuils(this.minDate, sixMonth)
                }
            }
        },
        addScenario(name) {
            this.removeScenario(name)
            if(this.selectedScenario.serieResultatModele.filter(x => this.$t(x.scenario.libelle.replace(/\s+/g, '')) == name).length === 0)
              return;

            let d = this.selectedScenario.serieResultatModele.filter(x => this.$t(x.scenario.libelle.replace(/\s+/g, '')) == name)
                            .map(d => { return { x: new Date(d.date), y: d.value } })
                            .sort((a, b) => (a.x > b.x) ? 1 : -1)
            
            let scenario = {
                dataPoints: d,
                type: 'spline',
                lineThickness: 1,
                name: name,
                lineDashType: "dash",
                color: this.getPrevColor(name),
                axisYIndex: this.getYAxis(`${this.$t('niveaumngf') }`),
                showInLegend: true
            }
            this.layout.data.push(scenario)
            let prevMaxDate = d[d.length - 1].x
            if(prevMaxDate > this.maxDate)
                this.maxDate = prevMaxDate
        },
        removeScenario(name) {
            let dataIndex = this.layout.data.map(d => d.name).indexOf(name);
            if(dataIndex >= 0) {
                this.layout.data.splice(dataIndex, 1)
                this.chart.data[dataIndex].remove()
            }
        },
        getIpsArea(){
            let dix_ans_sec;    
            let cinq_ans_sec;
            let deux_ans_sec;
            let deux_ans_hum;
            let cinq_ans_hum;
            let dix_ans_hum;
            let sup_dix_ans_hum;
            for (let i= 0; i < this.ips.periodesRetour.length; i++){
                let name = this.ips.periodesRetour[i].name
                if (name === "periodeRetour10ansSec"){
                        dix_ans_sec= this.ips.periodesRetour[i].values.map(d => { return { x: d.month, y: Math.round(d.value * 100)/100 }; })
                }else if (name === "periodeRetour5ansSec"){
                        cinq_ans_sec= this.ips.periodesRetour[i].values.map(d => { return { x: d.month, y: Math.round(d.value * 100)/100 }; })
                }else if (name === "periodeRetour2ansSec"){
                        deux_ans_sec= this.ips.periodesRetour[i].values.map(d => { return { x: d.month, y: Math.round(d.value * 100)/100 }; })
                }else if (name === "periodeRetour2ansHumide"){
                        deux_ans_hum= this.ips.periodesRetour[i].values.map(d => { return { x: d.month, y: Math.round(d.value * 100)/100 }; })
                }else if (name === "periodeRetour5ansHumide"){
                        cinq_ans_hum= this.ips.periodesRetour[i].values.map(d => { return { x: d.month, y: Math.round(d.value * 100)/100 }; })
                }else if (name === "periodeRetour10ansHumide"){
                        dix_ans_hum= this.ips.periodesRetour[i].values.map(d => { return { x: d.month, y: Math.round(d.value * 100)/100 }; })
                        sup_dix_ans_hum = dix_ans_hum.map(d=> {return {x: d.x, y: Math.ceil(1)}})
                }
            }

            return {dix_ans_sec: dix_ans_sec,
              cinq_ans_sec: cinq_ans_sec,
              deux_ans_sec: deux_ans_sec,
              deux_ans_hum: deux_ans_hum,
              cinq_ans_hum: cinq_ans_hum,
              dix_ans_hum: dix_ans_hum,
              sup_dix_ans_hum}

        },
        addIpsArea(scenario, name) {
            if(this.ipsArea && this.IncludeIPS && this.Piezo) {
                this.removeScenario(scenario)
                let scenarios = Object.getOwnPropertyNames(this.ipsArea)
                let currentId = scenarios.indexOf(scenario)

                let d = this.ipsArea[scenario]

                if(d) {
                    
                    let values = []
                    let minimum = Math.min(...this.piezoData.dataPoints.map(x => x.y))
                    let maximum = Math.max(...this.piezoData.dataPoints.map(x => x.y))

                    if(scenario === 'dix_ans_sec') {
                        values = d.map(v => { return { "x": new Date(new Date().getFullYear(), v.x - 1, 15), "y": [ minimum - minimum * 0.01, v.y ] } })
                    } else if (scenario === 'sup_dix_ans_hum') {
                        let previous = this.ipsArea[scenarios[currentId - 1]]
                        values = d.map(v => { return { "x": new Date(new Date().getFullYear(), v.x - 1, 15), "y": [ previous.filter(p => p.x === v.x).pop().y, maximum * 1.045] } })
                    } 
                    else if (scenario !== 'dix_ans_hum') {
                        let previous = this.ipsArea[scenarios[currentId - 1]]
                        values = d.map(v => { return { "x": new Date(new Date().getFullYear(), v.x - 1, 15), "y": [ previous.filter(p => p.x === v.x).pop().y, v.y ] } })
                    } else if (scenario === 'dix_ans_hum') {
                        let previous = this.ipsArea[scenarios[currentId - 1]]
                        values = d.map(v => { return { "x": new Date(new Date().getFullYear(), v.x - 1, 15), "y": [ previous.filter(p => p.x === v.x).pop().y, v.y ] } })
                    }

                    let area = {
                        dataPoints: values,
                        type: 'rangeArea',
                        lineThickness: 0,
                        fillOpacity: .8,
                        markerSize: 0,
                        markerType: null,
                        name: name,
                        showInLegend: false,
                        color: this.getIPSColor(scenario)
                    }

                    this.layout.data.unshift(area)
                }
            }
        },
        showIps() {
            if(!this.IncludeIPS)
                return

            this.addIpsArea("dix_ans_sec", this.$t('verylowlevel'))
            this.addIpsArea("cinq_ans_sec", this.$t('lowlevel') )
            this.addIpsArea("deux_ans_sec", this.$t('modlowlevel') )
          //  this.addIpsArea("mediane", "Niveaux autour de la normale")
            this.addIpsArea("deux_ans_hum", this.$t('meanlevel'))
            this.addIpsArea("cinq_ans_hum", this.$t('modhighlevel'))
            this.addIpsArea("dix_ans_hum", this.$t('highlevel'))
            this.addIpsArea("sup_dix_ans_hum", this.$t('veryhighlevel'))

            try {
                this.chart.render()
            } catch (e) {
                console.warn(e)
            }
        },
        hideIps() {
            if(!this.IncludeIPS)
                return

            this.removeScenario(this.$t('verylowlevel'))
            this.removeScenario(this.$t('lowlevel'))
            this.removeScenario(this.$t('modlowlevel'))
            this.removeScenario(this.$t('meanlevel'))
            this.removeScenario(this.$t('modhighlevel') )
            this.removeScenario(this.$t('highlevel'))
            this.removeScenario(this.$t('veryhighlevel'))

            try {
                this.chart.render()
            } catch (e) {
                console.warn(e)
            }
        },
        getIPSColor(name) {
            switch(name) {
                case 'dix_ans_sec':
                    return '#ff0000';
                case 'cinq_ans_sec':
                    return '#ffb700';
                case 'deux_ans_sec':
                    return '#ffff00';
                case 'mediane':
                    return '#42ff00';
                case 'deux_ans_hum':
                    return '#42ff00';
                    //return '#00ffff';
                case 'cinq_ans_hum':
                    return /* '#3661ff'; */ '#00ffff';
                case 'dix_ans_hum':
                    return /* '#0000ff'; */ '#3661ff';
                case 'sup_dix_ans_hum':
                    return '#0000ff';
                default:
                    return String.Empty;
            }
        },
        switchPrevision(e) {
            this.selectedScenario = e
            this.addScenario(this.$t('samplingnorain'))
            this.addScenario(this.$t('sampling10dry'))
            this.addScenario(this.$t('sampling5dry'))
            this.addScenario(this.$t('samplingMean'))
            this.addScenario(this.$t('sampling5wet'))
            this.addScenario(this.$t('sampling10wet'))
            this.chart.render()
        },
        toggleIps: function() {
            if(this.ipsEnabled) {
                this.hideIps()
                this.ipsEnabled = false
            } else {
                this.showIps()
                this.ipsEnabled = true
            }
        },
        async switchToModelResult(e) {
            this.selectedModelResult = e
            await ModelService.getSpecificModelResult(this.ModelId, e.id).then(data => {
                this.modelData = data
                this.modelData.forEach(d => {
                    if(d.anneePrelevements === 0) {
                        d.scenarioTitle =`${this.$t('samplingDontUse') }`
                    } else if (d.scenarioPrelevements === 'AnneeSpecifique') {
                        d.scenarioTitle = `${this.$t('speYear') } (${this.$t('basedOn') } ${d.anneePrelevements})`
                    } else {
                        d.scenarioTitle = `${d.scenarioPrelevements} (${this.$t('basedOn') }  ${d.anneePrelevements})`
                    }
                })

                if(this.modelData.length === 3) {
                    this.selectedScenario = this.modelData[1]
                } else {
                    this.selectedScenario = this.modelData[0]
                }

                this.addScenario(this.$t('samplingnorain'))
                this.addScenario(this.$t('sampling10dry'))
                this.addScenario(this.$t('sampling5dry'))
                this.addScenario(this.$t('samplingMean'))
                this.addScenario(this.$t('sampling5wet'))
                this.addScenario(this.$t('sampling10wet'))
            })
        },
        async addSeuils(minDate, maxDate) {
            await SensorService.getSeuilsPiezo(this.Piezo).then(seuils => {
                seuils.forEach(s => {
                    let seuil = {
                        dataPoints: [],
                        type: 'stepLine',
                        lineThickness: 1,
                        fillOpacity: 1,
                        markerType: 'none',
                        name: `${this.$t(s.nom)}`,
                        showInLegend: true,
                        color: s.couleur
                    }

                    for(var date = new Date(minDate); date <= maxDate; date.setDate(date.getDate() + 1)) {
                        let d = new Date(date)
                        switch(d.getMonth()) {
                            case 0:
                                seuil.dataPoints.push({x: d, y: s.jan})
                                break
                            case 1:
                                seuil.dataPoints.push({x: d, y: s.fev})
                                break
                            case 2:
                                seuil.dataPoints.push({x: d, y: s.mar})
                                break
                            case 3:
                                seuil.dataPoints.push({x: d, y: s.avr})
                                break
                            case 4:
                                seuil.dataPoints.push({x: d, y: s.mai})
                                break
                            case 5:
                                seuil.dataPoints.push({x: d, y: s.jun})
                                break
                            case 6:
                                seuil.dataPoints.push({x: d, y: s.jul})
                                break
                            case 7:
                                seuil.dataPoints.push({x: d, y: s.aou})
                                break
                            case 8:
                                seuil.dataPoints.push({x: d, y: s.sep})
                                break
                            case 9:
                                seuil.dataPoints.push({x: d, y: s.oct})
                                break
                            case 10:
                                seuil.dataPoints.push({x: d, y: s.nov})
                                break
                            case 11:
                                seuil.dataPoints.push({x: d, y: s.dec})
                                break
                        }
                    }
                    if(this.IncludeIPS)
                        this.layout.data.splice(7, 0, seuil)
                    else
                        this.layout.data.unshift(seuil)
                });
            })
        },
        addYAxis(name, color){
            this.layout.axisY.push({ 
                title: name, 
                gridThickness: 1, 
                titleFontColor: color, 
                titleFontFamily: 'Roboto', 
                titleFontSize: 15,
                lineDashType: 'dot',
                labelFontFamily: 'Roboto',
                labelFontSize: 10,
                gridColor: 'dimgrey'
            })

            return this.layout.axisY.length - 1
        },
        getYAxis(name) {
            return this.layout.axisY.map(y => y.title).indexOf(name)
        },
        zoomPlot(startDate) {
            this.layout.axisX.viewportMinimum = startDate
            this.layout.axisX.viewportMaximum = this.nextThirtyDays
            this.chart.render()
        },
        getPrevColor(prevName) {
            switch (prevName) {
                case this.$t('samplingnorain'):
                    return 'mediumvioletred'
                case this.$t('sampling10dry'):
                    return 'orange'
                case this.$t('sampling5dry'):
                    return 'gold'
                case this.$t('samplingMean'):
                    return 'lightgreen'
                case this.$t('sampling5wet'):
                    return 'deepskyblue'
                case this.$t('sampling10wet'):
                    return 'mediumblue'
                default:
                    return null
            }
        },
        exportChart() {
            this.chart.exportChart({format: "png"});
        },
        downloadCurrentResult() {
            modelService.downloadModelResult(this.selectedModelResult.modeleId, this.selectedScenario.id).then(d => {
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(d));
                element.setAttribute('download', `export_model_${this.selectedModelResult.modeleId}_${this.Piezo.replace(/[^a-zA-Z0-9_]/gi, '_')}.csv`);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }
}
</script>
<style scoped>
.chart {
  min-width: 100%;
  max-height: 100%;
  min-height: 300px;
}
h3.chart-title {
    text-align: center !important;
    display: block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

h3.chart-title a {
    text-decoration: none;
}

h3.chart-title a i.v-icon {
    margin-top: -2px;
}
.ips-legend {
    position: absolute;
    left: 45px;
    bottom: 13px;
    z-index: 999;
}
</style>
